/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const data = require("./data.json");

function parseRent(rentLabel) {
  let rent;
  let index;
  const rentArray = rentLabel.split(" – ");
  rent = 0;
  for (index = 0; index < rentArray.length; index += 1) {
    rent += parseInt(rentArray[index].replace(/[$,]+/g, ""), 10);
  }
  rent /= rentArray.length;
  if (Number.isNaN(rent)) {
    rent = 0;
  }
  return rent;
}

function parseSqft(sqftLabel) {
  let index;
  let sqft = 0;
  const sqftArray = sqftLabel.split(" – ");
  for (index = 0; index < sqftArray.length; index += 1) {
    sqft += parseInt(sqftArray[index].replace(/[^0-9]/g, ""), 10);
  }
  sqft /= sqftArray.length;
  if (Number.isNaN(sqft)) {
    sqft = 0;
  }
  return sqft;
}

function parseInfo(details) {
  let index;
  let infoString;
  const infoData = {};
  for (index = 0; index < details.length; index += 1) {
    infoString = details[index];
    if (infoString.includes("bed")) {
      infoData.numOfBed = parseInt(infoString.replace(/[^0-9]/g, ""), 10);
    } else if (infoString.toLowerCase().includes("studio")) {
      infoData.numOfBed = 0;
    } else if (infoString.includes("bath")) {
      infoData.numOfBath = parseInt(infoString.replace(/[^0-9]/g, ""), 10);
    } else if (infoString.includes("sq ft")) {
      infoData.sqft = parseSqft(infoString);
    }
  }
  return infoData;
}

function sameModels(model1, model2) {
  if (model1.numOfBed !== model2.numOfBed) {
    return false;
  }
  if (model1.numOfBath !== model2.numOfBath) {
    return false;
  }
  if (Math.abs(model1.rent - model2.rent) > 100) {
    return false;
  }
  return true;
}

function parseModels(models) {
  let index;
  let num;
  let rawModel;
  let model = {};
  let findExisting;
  let info = {};
  const modelArray = [];
  for (index = 0; index < models.length; index += 1) {
    info = {};
    rawModel = models[index];
    model = {};
    model.rent = parseRent(rawModel.rentLabel);
    info = parseInfo(rawModel.details);
    model.numOfBed = info.numOfBed;
    model.numOfBath = info.numOfBath;
    model.sqft = info.sqft;
    if (model.rent !== 0) {
      findExisting = false;
      for (num = 0; num < modelArray.length; num += 1) {
        if (sameModels(modelArray[num], model)) {
          findExisting = true;
          break;
        }
      }
      if (!findExisting) {
        modelArray.push(JSON.parse(JSON.stringify(model)));
      }
    }
  }
  return modelArray;
}

export function getData() {
  let index;
  let apartmentRaw;
  let models;
  let model;
  let count;
  let propertyName;
  let postalCode;
  let city;
  const dataTableData = {
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "city", accessor: "city", width: "6%" },
      { Header: "postalCode", accessor: "postalCode", width: "6%" },
      { Header: "#Bed", accessor: "numOfBed" },
      { Header: "#Bath", accessor: "numOfBath" },
      { Header: "sqft", accessor: "sqft" },
      { Header: "rent", accessor: "rent" },
    ],
    rows: [],
  };
  for (index = 0; index < data.length; index += 1) {
    apartmentRaw = data[index];
    propertyName = apartmentRaw.propertyName;
    city = apartmentRaw.location.city;
    postalCode = apartmentRaw.location.postalCode;
    models = parseModels(apartmentRaw.models);
    for (count = 0; count < models.length; count += 1) {
      model = models[count];
      dataTableData.rows.push({
        name: propertyName,
        city,
        postalCode,
        numOfBed: model.numOfBed,
        numOfBath: model.numOfBath,
        rent: model.rent,
        sqft: model.sqft,
      });
    }
  }
  return dataTableData;
}

const dataTableData = getData();

export default dataTableData;
